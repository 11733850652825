var IDX = {};
//这儿得从url中取下值
IDX.getParam = {
  page: 0,
  page_size: 20,
  industry_id: UTL.getUrlParam("industry_id") || "",
  stage_id: UTL.getUrlParam("stage_id") || "",
  cate_id: _cate_id,
  journalist_id: ""
};

//首页
IDX.init = function () {
  IDX.enablePcIndFilter();
  IDX.enableStageFilter();
  IDX.enableScrollEffect();
  // UTL.showUpgradeIEtips()
  IDX.handleClickBanner();
  IDX.handleCertBtnHide();
  IDX.handleShowAdvertisement();
};

// 处理顶部广告的展示
IDX.handleShowAdvertisement = function () {
  var closeAdvertisementArr = localStorage.getItem('closeAdvertisementArr') ? localStorage.getItem('closeAdvertisementArr').split(',') : [];
  // 获取当前广告ID
  var AdvertisementId = $('.header-banner').attr('data-id');
  if (closeAdvertisementArr.indexOf(AdvertisementId) > -1) {
    $('.header-banner').hide();
  } else {
    $('.header-banner').show();
  }
  // 点击关闭广告，并缓存对应的广告ID
  $('.header-banner .closeAd-box').on('click', function (e) {
    e.preventDefault();
    closeAdvertisementArr.push(AdvertisementId);
    localStorage.setItem('closeAdvertisementArr', closeAdvertisementArr.toString());
    $('.header-banner').slideUp();
  })
}

// 右侧sidebar认证按钮显示隐藏问题
IDX.handleCertBtnHide = function () {
  if ($.jStorage.get("tempCertState") == 1) {
    $(".js-certbtn").html(
      `<a href="/projectlist" target="_blank">
      <span class="btn_center">查看更多项目</span>
      </a>`);
  } else {
    $(".js-certbtn").html(`<a href="/datacert">
        <span class="btn_left">立即认证</span>
    </a>
    <a href="/projectlist" target="_blank">
        <span class="btn_right">查看更多项目</span>
    </a>`);
  }
}

// 神策采集banner点击
IDX.handleClickBanner = function () {
  $(".main_focus .carousel-inner").on("click", ".mainfocus_item", function (e) {
    var article_id = parseInt($(this).attr("data-id"));
    var banner_index = $(this).attr("data-ind");
    var data_title = $(this).attr("data-title");
    // console.log({
    //     "$lib_detail": '大banner点击##点击banner触发##index2.0.js##30',
    //     "bannerType": "大焦点",
    //     "bannerIndex": banner_index,
    //     "articleTitle": data_title,
    //     "articleID": article_id
    // })
    sa.track("articleBanner", {
      $lib_detail: "大banner点击##点击banner触发##index2.0.js##30",
      bannerType: "大焦点",
      bannerIndex: banner_index,
      articleTitle: data_title,
      articleID: article_id,
      "PlatformType": "PC"
    });
  });
  $(".minor_focus_col .minor_focus").on("click", ".minor_focus_block", function (
    e
  ) {
    var article_id = parseInt($(this).attr("data-id"));
    var banner_index = $(this).attr("data-ind");
    var data_title = $(this).attr("data-title");
    sa.track("articleBanner", {
      $lib_detail: "小banner点击##点击banner触发##index2.0.js##54",
      bannerType: "小焦点",
      bannerIndex: banner_index,
      articleTitle: data_title,
      articleID: article_id,
      "PlatformType": "PC"
    });
  });
};

IDX.enablePcIndFilter = function () {
  // 处理文本溢出问题 ---start
  // $(".album-title,.album-desc").each(function (i) {
  //   var lineHeight = parseInt($(this).css("line-height"));
  //   var height = parseInt($(this).height());
  //   if ((height / lineHeight) > 2) {
  //     $(this).addClass("util-words")
  //     $(this).css("height", "85px");
  //   } else {
  //     $(this).removeClass("util-words");
  //   }
  // });
  // 处理文本溢出问题 ---end

  $("#industry_filter").on("click", ".more", function () {
    $(this)
      .siblings()
      .css({
        display: "inline-block"
      });
    $("#industry_filter .less").css({
      display: "inline-block"
    });
    $(this).hide();
  });
  $("#industry_filter").on("click", ".less", function () {
    $(this)
      .siblings("._more")
      .css({
        display: "none"
      })
      .siblings(".more")
      .show();
    $(this).hide();
  });
  $("#industry_filter").on("click", ".industry", function () {
    $("#industry_filter li span").removeClass("active");
    $(this)
      .find("span")
      .addClass("active");

    var industry_id = $(this).data("industry-id");

    IDX.getParam.page = 0;
    IDX.getParam.industry_id = industry_id;

    // History.replaceState({ state: 1 }, web_name, "?industry_id=" + industry_id);
    History.replaceState({
      state: 1
    },
      web_name,
      "?" +
      UTL.object2query({
        industry_id: industry_id,
        stage_id: IDX.getParam.stage_id
      })
    );

    $.jStorage.set("articlesPage", "1");
    $("#artciles_field").empty();
    IDX.showCircleLoadingGif();

    IDX.getArticlesList_2(IDX.getParam, function (data) {
      showArticlesList(data, $("#artciles_field"));
      IDX.shouldShowLoadmore(data.data.articles.length);
      resetArticlePages();
      // showArticlesPages(data.data._pageinfo.pages);
    });
  });
};
IDX.enableStageFilter = function () {
  //融资阶段筛选
  $("li[stage_id]").click(function () {
    $("#articles_pages_container").hide();
    $("#artciles_field").empty();
    IDX.showCircleLoadingGif();

    $(this)
      .addClass("active")
      .siblings()
      .removeClass("active");

    var stageId = $(this).attr("stage_id");
    IDX.getParam.page = 0;
    IDX.getParam.stage_id = stageId;
    // History.replaceState({ state: 1 }, web_name, "?stage_id=" + stageId);
    History.replaceState({
      state: 1
    },
      web_name,
      "?" +
      UTL.object2query({
        industry_id: IDX.getParam.industry_id,
        stage_id: IDX.getParam.stage_id
      })
    );

    IDX.getArticlesList_2(IDX.getParam, function (data) {
      showArticlesList(data, $("#artciles_field"));
      IDX.shouldShowLoadmore(data.data.articles.length);
      resetArticlePages();
      // showArticlesPages(data.data._pageinfo.pages);
    });
  });
};
IDX.shouldShowLoadmore = function (len) {
  if (len < IDX.getParam.page_size) {
    IDX.showNomoreText();
  } else {
    IDX.showLoadmoreText();
  }
};
IDX.showCircleLoadingGif = function () {
  $(".load_more_articles").show();
  $(".load_more_articles>*").hide();
  $(".load_more_articles img").show();
};
IDX.showLoadmoreText = function () {
  $(".load_more_articles").show();
  $(".load_more_articles>*").hide();
  $(".load_more_articles span").show();
};
IDX.showNomoreText = function () {
  $(".load_more_articles").show();
  $(".load_more_articles>*").hide();
  $(".load_more_articles div").show();
};

// IDX.pages_con_fixed = false
IDX.enableScrollEffect = function () {
  var beforeScroll =
    document.documentElement.scrollTop || document.body.scrollTop;
  var oTop = 60;
  var target = 90;
  var topDistanceToTop = $("#mobile_filter_menu_con").offsetTop;
  var bottomDistaceToTop =
    $("#mobile_filter_menu_con").offsetHeight + topDistanceToTop;
  window.onscroll = function () {
    var afterScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    var d = afterScroll - beforeScroll;
    var clientWidth = document.body.clientWidth;

    if (clientWidth > 768) {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > target) {
        if (d > 15) {
          $("header").hide();
        }
        if (d < -15) {
          $("header").show();
        }
        beforeScroll = afterScroll;
      } else {
        $("header").show();
      }
    } else {
      if (d > 0) {
        if (afterScroll < bottomDistaceToTop) {
          $("#mobile_filter_menu_con").removeClass(
            "filter_menu_hide filter_menu_fixed"
          );
        } else {
          $("#mobile_filter_menu_con").addClass("filter_menu_hide");
        }
      } else {
        if (afterScroll < topDistanceToTop) {
          $("#mobile_filter_menu_con").removeClass(
            "filter_menu_hide filter_menu_fixed"
          );
        } else {
          $("#mobile_filter_menu_con")
            .addClass("filter_menu_fixed")
            .removeClass("filter_menu_hide");
        }
      }
      beforeScroll = afterScroll;
    }
  };
};

var _timeStart = "";
var _journalistId = "";
var _pagesVisited = {
  page_1: "visited"
};


//如果有url参数，按照url参数显示文章
var _keyword = "";
if (getUrlParam("keyword")) {
  _keyword = getUrlParam("keyword");
}

//调整main focus大小
(function () {
  if (document.body.clientWidth < 768) {
    var setHeight = ($(".main_focus").width() * 180) / 375;
    $(".main_focus>div").css("height", setHeight);
    $(".carousel-inner .item").css("height", setHeight);
    $("a[href='#main_focus_img']").css("line-height", setHeight);
  }
})();

shortRcomItemTitle();
//侧边栏的title长短控制在23个字以内，多余部分用...代替
function shortRcomItemTitle() {
  var itemsArr = $(".recent_title");
  // console.log(itemsArr);
  for (i = 0; i < itemsArr.length; i++) {
    if (itemsArr[i].innerHTML.length >= 20) {
      itemsArr[i].innerHTML = itemsArr[i].innerHTML.substring(0, 19) + "...";
    }
  }
}

//获取文章列表数据
function getArticlesList(
  page,
  page_size,
  industry_id,
  cate_id,
  journalist_id,
  time_start,
  stage_id,
  keyword,
  fun
) {
  // console.log(12312312312)
  var token = getCookie("token");
  var sa_obj = getCookie("sa_obj");
  $.get({
    url: v1url + "articles",
    timeout: 6000, //        type:"GET",
    headers: {
      devicetoken: cookie_PENCILNEWSID,
      token
    },
    data: {
      page: page,
      page_size: page_size,
      industry_id: industry_id,
      cate_id: cate_id,
      journalist_id: journalist_id,
      time_start: time_start,
      stage_id: stage_id,
      keyword: keyword,
      "sa-super-property": sa_obj
    },
    success: function (data) {
      if (typeof data == "string") {
        data = $.parseJSON(data);
      }
      fun(data);
    },
    error: function (ejqXHR, textStatus, errorThrown) {
      if (textStatus === "timeout") {
        // alert("请求超时"); //Handle the timeout
      }
    }
  });
}

//获取文章列表数据_2
// getParam = {
//     page: page,
//     page_size: page_size,
//     industry_id: industry_id,
//     cate_id: cate_id,
//     journalist_id: journalist_id,
//     time_start: time_start,
//     stage_id: stage_id,
//     keyword: keyword
// }

IDX.getArticlesList_2 = function (getParam, cb) {
  var token = getCookie("token");
  var sa_obj = getCookie("sa_obj");
  $.get({
    url: v1url + "articles",
    headers: {
      devicetoken: cookie_PENCILNEWSID,
      token
    },
    data: $.extend({}, getParam, {
      "sa-super-property": sa_obj
    }),
    dataType: "json",
    success: function (data) {
      if (typeof data == "string") {
        data = $.parseJSON(data);
      }
      cb(data);
    },
    error: function (ejqXHR, textStatus, errorThrown) {
      console.log(ejqXHR);
    }
  });
};

//展示文章列表
//data 返回数据  obj jq对象，在jq对象中显示数据
function showArticlesList(data, obj) {
  _timeStart = data.data._pageinfo.time_start;
  var pageInfo = data.data._pageinfo;
  var articles = data.data.articles;
  var artcilesListHTML = "";
  $(".left_block_title .js-showArticleNumber").html(pageInfo.total_count);
  if (articles.length < 20) {
    $(".load_more_articles img").hide();
    $(".load_more_articles span").hide();
    $(".load_more_articles div").show();
  }
  for (i = 0; i < articles.length; i++) {
    console.log(articles[i])
    var articleInfo = articles[i].article_info;
    var cate = articles[i].cate;
    var author = articles[i].author;
    var authorProfile = author.profile;

    var articleId = articleInfo.article_id;
    var coverImg = articleInfo.cover_img;
    var coverUrl = imgUrl + coverImg + imageview1x;
    var artcileTitle = articleInfo.title;

    var cateId = cate.cate_id;

    var reg = new RegExp("[5-9]|[1-9][1-9]");
    var articleUrl = "/p/" + articleId + ".html?from=article_list";
    if (articles[i].campaign != null) {
      if (articles[i].campaign.district) {
        articleUrl = "/d/" + articleId;
      }
    }

    var cateName = cate.name;

    var articleDigest = articleInfo.digest;
    var authorAvatar = "/imgs/defaultAva200.png";
    if (authorProfile.avatar) {
      authorAvatar = imgUrl + authorProfile.avatar + imageview1x;
    }

    var authorId = author.uid;
    var authorName = authorProfile.name;
    var createAt = articleInfo.create_at;
    var deaufalt_img = "'/imgs/qianbidao.png'";

    createAt = timeFormatConvert(createAt);
    artcilesListHTML +=
      `<div class="article_block">
          <div class="article_img">
              <a href="${articleUrl}" target="_blank">
                <img src="${coverUrl}" alt="${artcileTitle}" onerror="this.src=${deaufalt_img}">
              </a>
          </div>
          <div class="article_content">
              <h3><a href="${articleUrl}" target="_blank">${artcileTitle}</a></h3>
              <div class="article_digest">
                <a href="${articleUrl}" target="_blank">
                  <span>${articleDigest}</span>
                </a>
              </div>
              <div class="article_author">
                <div>
                  <span class="cate-tag-${cateId} cate-tag">${cateName}</span>
                </div>
                <div class="author_items">
  ${!author['uid'] ? !!authorName ? `` : `<a style="color: #9b9b9b" class="author_name"><span>${authorName} </span></a>` : `<a href="/journalist/${authorId}"  class="author_name" ><span>${authorName} </span></a>`}
                      <span>${createAt}</span>
                    </div>
                </div>
            </div>
      </div>`
  }
  obj.append(artcilesListHTML);
}

//加载更多
var articlesPage = 1;
$(".load_more_articles span").click(function () {
  if (articlesPage >= 5) {
    return;
  }
  $("#articles_pages_container").hide();
  IDX.showCircleLoadingGif();
  var stageId = "";
  if (getUrlParam("stage_id")) {
    stageId = getUrlParam("stage_id");
  }
  var industryId = "";
  if (getUrlParam("industry_id")) {
    industryId = getUrlParam("industry_id");
  }
  if ($.jStorage.get("articlesPage")) {
    articlesPage = 1;
  }

  // console.log(IDX._pageinfo)
  if (articlesPage >= 4) {
    showArticlesPages(IDX._pageinfo.pages);
    $("#articles_pages_container").show();
    $(".load_more_articles").hide();
    return;
  }

  getArticlesList(
    articlesPage,
    20,
    industryId,
    _cate_id,
    _journalistId,
    _timeStart,
    stageId,
    _keyword,
    function (data) {
      // console.log(_journalistId);
      IDX._pageinfo = data.data._pageinfo;
      showArticlesList(data, $("#artciles_field"));
      articlesPage++;
      $.jStorage.deleteKey("articlesPage");
      if (data.data.articles.length < 20) {
        IDX.showNomoreText();
      } else {
        IDX.showLoadmoreText();
      }
    }
  );
});

function saveUser(user) {
  //uid, username, name, phone, email, token, subscribed, avatar
  window.name = JSON.stringify(user);
}

function loadUserInfo() {
  var info = getCookie("user-info");
  if (info) {
    var unicode = BASE64.decoder(info);
    var str = "";
    for (var i = 0, len = unicode.length; i < len; ++i) {
      str += String.fromCharCode(unicode[i]);
    }
    var data = JSON.parse(str);
    var newtoken = data.token;
    var oldtoken = $.jStorage.get("localToken");
    var redirectURL = data.redirectURL;
    if (newtoken != oldtoken) {
      delCookie("user-info");
      var tempToken = data["token"];
      var tempUsername = data["profile"]["username"];
      var tempTokenExpired = data["token_expired"];
      var tempSubscribe = data["profile"]["subscribed"];
      var tempEmail = data["profile"]["email"];
      var tempUid = data["profile"]["uid"];
      var tempName = data["profile"]["name"];
      var tempAvatar = imgUrl + data["profile"]["avatar"];
      var tempPhone = data["profile"]["phone"];
      $.jStorage.set("localToken", tempToken);
      $.jStorage.set("localUsername", tempUsername);
      $.jStorage.set("localSubscribe", tempSubscribe);
      $.jStorage.set("localEmail", tempEmail);
      $.jStorage.set("localUid", tempUid);
      $.jStorage.set("localName", tempName);
      $.jStorage.set("tempAvatar", tempAvatar);
      $.jStorage.set("tempPhone", tempPhone);

      saveUser({
        uid: tempUid,
        username: tempUsername,
        name: tempName,
        phone: tempPhone,
        email: tempEmail,
        token: tempToken,
        subscribed: tempSubscribe,
        avatar: tempAvatar
      });
      location.href = redirectURL ? redirectURL : "/";
    } else {
      delCookie("user-info");
      location.href = redirectURL ? redirectURL : "/";
    }
  }
}

//点击关闭顶部banner
$("#close_top_banner").click(function () {
  $(".top_banner_tips").hide();
});

function resetArticlePages() {
  _pagesVisited = {
    page_1: "visited"
  };
  articlesPage = 1;
  $(".pages_appended").remove();
  $("#articles_pages_container").hide();
  $("#artciles_field").show();
}

function showArticlesPages(pages) {
  var pageContainerHTML = "";
  var i = 2;
  for (; i <= pages; i++) {
    pageContainerHTML +=
      "<div class='pages_appended' pageNum='" + i + "'></div>";
  }
  var midRange = 4;
  if (!IsPC()) {
    midRange = 1;
  }

  $("#articles_list_container").append(pageContainerHTML);
  $("#articles_pages").jPages({
    containerID: "articles_list_container",
    perPage: 1,
    startPage: articlesPage + 1,
    startRange: 0,
    midRange: midRange,
    minHeight: false,
    first: "首页",
    callback: function (pages, items) {
      var stageId = "";
      if (getUrlParam("stage_id")) {
        stageId = getUrlParam("stage_id");
      }
      var showPage = pages.current;
      var sourcePage = showPage - 1;
      if (_pagesVisited["page_" + showPage] == "visited") {
        // document.body.scrollTop = ($(".article_stage")||$("#articles_list_container")).offset().top;
        document.body.scrollTop = $("#articles_list_container").offset().top;
        return;
      }
      _pagesVisited["page_" + showPage] = "visited";

      showLoadingGif();

      IDX.getParam.page = sourcePage;
      IDX.getArticlesList_2(IDX.getParam, function (data) {
        closeLoadingGif();
        showArticlesList(data, $("div[pageNum=" + showPage + "]"));
        // document.body.scrollTop = ($(".article_stage")||$("#articles_list_container")).offset().top;
        document.body.scrollTop = $("#articles_list_container").offset().top;
      });
    }
  });
  $("#page-jump-btn").click(function () {
    var pageNum = $("#page-jump-input").val();
    pageNum = parseInt(pageNum);
    if (pageNum > 0 && pageNum <= pages) {
      $("#articles_pages").jPages(pageNum);
      $("#page-jump-input").val("");
    } else {
      showWarning("请输入有效的页码！");
    }
  });
}